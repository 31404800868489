<template>
  <div class="folders">
    <div class="heading">
      <div class="w-auto md:w-full"/>
    </div>
    <div class="folder-content">
      <folder-container-list>
        <folder-element-list
            :key="element.id"
            :element="element"
            :showEditButtons="showEditButtons"
            @itemDropped="handleDrop"
            v-for="element in elements"
            @select="selectElement(element)"
            @multiSelect="handleMultiSelect"
            :selected="selectedElements.includes(element.id)">
          {{ element.categoryname }}
        </folder-element-list>
      </folder-container-list>
    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import ContentMixin from '../../mixin/content'

export default {
// export default {
  props: [
    'layout',
    'category',
    'isDigitalSignage',
  ],
  computed: {
    ...mapGetters({
      sortType: 'category/sortType',
      sortDirection: 'category/sortDirection',
      selectedElements: 'category/selectedElements',
      showEditButtons: 'category/showEditButtons',
    }),
  },
  data() {
    return {
      elements: [],
      swapped: false,
    }
  },
  async created() {
    await this.loadCategories()
  },
  methods: {
    async selectElement(category) {
      const componentType = this.$helper.resolveComponentTypeByCategory(category)
      if (this.layout === 'splitview' && componentType !== 'folder') {
        return this.$emit('selectElement', category)
      }
      this.elements = await this.$api.findCategories(this.$helper.extractIdFromSlug(category.id))
      this.$store.commit('category/setElements', this.elements)
    },
    async handleMultiSelect($event) {
      let currentSelectedElements = JSON.parse(JSON.stringify(this.selectedElements))
      if (currentSelectedElements.includes($event)) {
        currentSelectedElements = currentSelectedElements.filter(element => element !== $event)
      } else {
        currentSelectedElements.push($event)
      }
      this.$store.commit('category/setSelectedElements', currentSelectedElements)
    },
    async handleDrop($event) {
      if (this.searched) return
      let mode = this.selectedElements.length > 0
          ? 'move'
          : 'swap'

      switch (mode) {
        case 'move':
          this.$store.commit('app/setLoading', true)
          await this.$api.moveCategory($event.target, this.selectedElements)
          this.$store.commit('app/setLoading', false)
          setTimeout(() => window.location.reload(), 200)
          break
        case 'swap':
          if (!this.sortType || this.sortType === 'default') {
            this.swapped = true
            let sourceIndex = this.elements.findIndex(element => element.id === $event.source)
            let targetIndex = this.elements.findIndex(element => element.id === $event.target)

            let tmp = this.elements[sourceIndex]
            this.elements[sourceIndex] = this.elements[targetIndex]
            this.elements[targetIndex] = tmp
            this.$store.commit('category/setElements', this.elements)
          }
          break
      }
    },
    async loadCategories() {
      this.elements = await this.$api.findCategories(this.$helper.extractIdFromSlug(this.category.id), this.sortType, this.sortDirection)
      this.$store.commit('category/setElements', this.elements)
    },
    async leaving(message) {
      if (this.swapped) {
        let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
        await this.$toast.success(message, { position: 'top-right'})
        await sleep(2000);
        let ids = []
        for (let i = 0; i < this.elements.length; i++) {
          ids.push(this.elements[i].id)
        }
        await this.$api.sortCategories(ids)
      }
    },
  },
  watch: {
    sortType() {
      this.loadCategories()
    },
    sortDirection() {
      this.loadCategories()
    },
  },
  mixins: [
    ContentMixin,
  ],
  inject: [
    '$api',
    '$helper',
  ],
  emits: [
    'selectElement'
  ]
}
</script>

<style lang="scss" scoped>
.folders {
  @apply
  md:pl-0
  md:pt-0
  md:flex
  md:w-full
  md:h-full
  md:flex-col;

  .heading {
    @apply
    flex
    p-2
    gap-2
    md:py-2
    md:px-7
    w-full
    items-center
    justify-between;

    .title {
      @apply
      text-2xl;
    }

    .searchbar {
      @apply
      w-full
      md:w-1/3;
    }
  }

  .folder-content {
    @apply
    flex
    w-full
    h-full
    items-center
    overflow-y-auto;
  }
}
</style>